
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import NotificationService from "@/buying-teams/services/NotificationService";

export default defineComponent({
    name: "sign-in",
    components: {
        Field,
        Form,
        ErrorMessage
    },
    setup() {
        const store = useStore();
        const email = "";
        let errorMsgVisible = ref(false);

        const submitButton = ref<HTMLButtonElement | null>(null);

        //Create form validation object
        const checkEmail = Yup.object().shape({
            email: Yup.string().email().required().label("Email")
        });

        const onSubmitCheckEmail = async (values) => {
            if (submitButton.value) {
                // eslint-disable-next-line
                submitButton.value!.disabled = true;
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            store.dispatch(Actions.CHECK_EMAIL, values)
                .then(res => {
                    let checkEmailResponse = res.data.data;
                    store.commit("setAuthConfigEmail", values.email);

                    if (checkEmailResponse.is_registered_user && !checkEmailResponse.is_mock_user) {
                        redirectToRouteWithName("continue-to-login");
                    } else if (checkEmailResponse.is_email_invitation_exist ||
                        (!checkEmailResponse.is_email_invitation_exist && !checkEmailResponse.is_domain_exist)) {
                        redirectToRouteWithName("create-password");
                    } else if (!checkEmailResponse.is_email_invitation_exist && checkEmailResponse.is_domain_exist) {
                        // TODO show access request screen
                        // TODO call /auth/access_request api
                    } else if (!checkEmailResponse.is_domain_exist) {
                        errorMsgVisible.value = true;
                    }
                })
                .catch(err => {
                    console.log("err = ", err);
                    NotificationService.swalError(err);
                })
                .finally(() => {
                    //Deactivate indicator
                    submitButton.value?.removeAttribute("data-kt-indicator");
                    // eslint-disable-next-line
                    submitButton.value!.disabled = false;
                });

        };

        const platformConfigs = computed(() => {
            return store.getters.platformConfigs
        })

        return {
            platformConfigs,
            onSubmitCheckEmail,
            checkEmail,
            submitButton,
            errorMsgVisible
        };
    }
});
